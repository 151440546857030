import React from 'react';
import styles from './validationPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import getColorPalette from './colorPalette.js';
import applyWrappers from 'util/ComponentWrapper';
import FilledButton from 'commonComponents/buttons/filledButton';
import HollowButton from 'commonComponents/buttons/hollowButton';
import DoubleQuotes from 'svgComponents/doubleQuotes';
import ColorHeading from 'commonComponents/colorHeading';
import { getSegment, getTouchPoint, getPersona } from 'util/utilFunctions';
import CloseIcon from 'svgComponents/closeIcon';
import analyticsUtil from 'util/segmentUtil';
class ValidationPopup extends React.Component {

    onClickOfClosePopup = () => {
        analyticsUtil.track("SIDEBAR_POPUP_CLOSE",{ popup: "VALIDATION_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickDefineAndProceedCallBack = () => {
        analyticsUtil.track("DEFINE_AND_PROCEED",{ method: "onClickDefineAndProceedCallBack" });
        this.props.moveToIdeate();
        this.props.postActionLog({
            type: 'MAKE_IDEATE_PHASE_AVAILABLE',
            payload: {
                currentPhaseKey: 'ideate'
            }
        });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickDefineAndProceed = () => {
        const userDefine = this.props.userPhasesState.get('userDefine');
        
        this.props.postActionLog({
            type: 'SET_USER_DEFINE_PHASE_STATE',
            payload: {
                activeDefineSegmentId: userDefine.get('activeDefineSegmentId'),
                activeDefineTouchpointId: userDefine.get('activeDefineTouchpointId'),
            }
        });

        this.props.postActionLog({
            type: 'SET_USER_IDEATE_PHASE_STATE',
            payload: {
                isUserOnFocusConfirmationScreen: true
            }
        }, this.onClickDefineAndProceedCallBack);
    };

    onClickChangeFocus = () => {
        analyticsUtil.track("CHANGE_FOCUS",{ method: "onClickChangeFocus" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
        this.props.setUserDefinePhaseState({
            activeDefineSegmentId: -1,
            activeDefineTouchpointId: -1
        });
    };


    getExhaustedStatus = () => {
        const userDefine = this.props.userPhasesState.get('userDefine');
        const selectedSegmentId = userDefine.get('activeDefineSegmentId');
        const selectedTouchPointId = userDefine.get('activeDefineTouchpointId');
        const focusValidationList = userDefine.get('focusValidationList');

        const selectedFocus = focusValidationList.find((eachFocus) => {
            return (
                eachFocus.segmentId === selectedSegmentId &&
                eachFocus.touchPointId === selectedTouchPointId
            );
        });
        if (selectedFocus) {
            return selectedFocus.isExhausted;
        }
        return false;
    }

    renderResponseCard = (myStyles, eachSuggestion) => {
        const personaDetails = getPersona(this.props.personas.get('personaList'), eachSuggestion.personaId).toJS();
        return (
            <div className={css(myStyles.responseCard)} styleName="response-card">
                <div styleName="double-quotes-top">
                    <DoubleQuotes />
                </div>
                <div styleName="double-quotes-bottom">
                    <DoubleQuotes />
                </div>
                <div styleName="response-image">
                    <img
                        alt="persona"
                        src={this.props.getImage(personaDetails.image_full)}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div
                    className={css(myStyles.responseContent)}
                    styleName="response-content"
                >
                    <div styleName="response-persona-name">
                        {this.props.getLabel(personaDetails.name)}
                    </div>
                    {this.props.getLabel(eachSuggestion.suggestion)}
        </div>
            </div>
        );
    };

    renderBorder = myStyles => {
        const myColors = getColorPalette(this.props.skinGuide, 3);
        return (
            <div
                className={css(
                    myStyles.borderContainer,
                    myColors.cardGradientBackground
                )}
                styleName="border-container"
            />
        );
    };

    renderHeading = myStyles => {
        return (
            <div styleName="heading-container">
                <ColorHeading title={this.props.getLabel('label_focus_validation_complete')} isUppercase={true} />
            </div>
        );
    };

    renderContent = myStyles => {
        const segments = this.props.segments.get('segmentList');
        const touchpoints = this.props.touchPoints.get('touchPointList');

        const userDefine = this.props.userPhasesState.get('userDefine');
        const activeDefineSegmentId = userDefine.get('activeDefineSegmentId');
        const activeDefineTouchpointId = userDefine.get('activeDefineTouchpointId');
        const focusValidationList = userDefine.get('focusValidationList');

        const currentSegment = getSegment(
            segments,
            activeDefineSegmentId
        );
        const currentTouchPoint = getTouchPoint(
            touchpoints,
            activeDefineTouchpointId
        );
        let personaSuggestions = [];
        if(userDefine.get('isFocusValidationFetched')) {
            const currentFocusValidation = focusValidationList.find((eachItem) => {
                return (
                    eachItem.segmentId === activeDefineSegmentId
                    && eachItem.touchPointId === activeDefineTouchpointId
                );
            });
            personaSuggestions = currentFocusValidation.personaSuggestions;
        }

        let responseCards = [];

        if(personaSuggestions.length > 0) {
            responseCards = personaSuggestions.map((eachSuggestion) => {
                return this.renderResponseCard(myStyles, eachSuggestion);
            });
        }

        return (
            <div styleName="content-container">
                <div styleName="content-selected-heading-container">
                    <div styleName="single-heading-box">
                        <div
                            className={css(myStyles.singleHeading)}
                            styleName="single-heading"
                        >
                            {this.props.getLabel(currentSegment.get('name'))}
                        </div>
                        <img
                            alt="segment"
                            src={this.props.getImage(currentSegment.get('image'))}
                            width="78px"
                            height="58px"
                            styleName="single-heading-img"
                        />
                    </div>
                    <div className={css(myStyles.andHeading)} styleName="and-container">
                        &
                    </div>
                    <div styleName="single-heading-box">
                        <div
                            className={css(myStyles.singleHeading)}
                            styleName="single-heading"
                        >
                            {this.props.getLabel(currentTouchPoint.get('name'))}
                        </div>
                        <img
                            alt="tp"
                            src={this.props.getImage(currentTouchPoint.get('image'))}
                            width="78px"
                            height="58px"
                            styleName="single-heading-img"
                        />
                    </div>
                </div>
                <div styleName="content-selected-response-container">
                    {responseCards}
                </div>
            </div>
        );
    };

    renderButtons = (myStyles, isExhausted) => {
        return (
            <div
                className={css(myStyles.buttonsContainer)}
                styleName="buttons-container"
            >
                <div styleName="action-button">
                    <HollowButton
                        clickFunction={() => {
                            this.onClickChangeFocus();
                        }}
                        textLabel={this.props.getLabel('label_change_focus')}
                    />
                </div>
                <div styleName="action-button">
                    <FilledButton
                        clickFunction={() => {
                            this.onClickDefineAndProceed();
                        }}
                        textLabel={this.props.getLabel('label_define_proceed')}
                        disableButton={isExhausted ? true : false}
                    />
                </div>
            </div>
        );
    };

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const isExhausted = this.getExhaustedStatus();
        return (
            <div
                className={css(myStyles.validationContainer)}
                styleName="validation-popup-container"
            >
                {this.renderCloseIcon(myStyles)}
                {this.renderBorder(myStyles)}
                {this.renderHeading(myStyles)}
                {this.renderContent(myStyles)}
                {this.renderButtons(myStyles, isExhausted)}
                {
                    isExhausted
                    ?   <div className={css(myStyles.buttonHelpTextExhausted)} styleName="button-help-text-exhausted">
                            {this.props.getLabel('label_ideas_exhausted')}
                        </div>
                    :   null
                }
            </div>
        );
    }
}

export default applyWrappers(ValidationPopup, styles);
